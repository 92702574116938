export const timelineOptions = [
  { name: "NA", value: "NA" },
  { name: "Within a Month", value: "Within a Month" },
  { name: "1 to 3 Months", value: "1 to 3 Months" },
  { name: "3 to 6 Months", value: "3 to 6 Months" },
  { name: "More than 6 Months", value: "More than 6 Months" },
];

export const priorityOptions = [
  { name: "NA", value: "NA" },
  { name: "Extremely Urgent", value: "Extremely Urgent" },
  { name: "Urgent", value: "Urgent" },
  { name: "High", value: "High" },
  { name: "Medium", value: "Medium" },
  { name: "Low", value: "Low" },
];

export const accountStatusOptions = [
  { name: "Unverified", value: "Unverified" },
  { name: "Verified", value: "Verified" },
  { name: "Disabled", value: "Disabled" },
  { name: "Blocked", value: "Blocked" },
];
export const statusOptions = [
  { name: "DISABLED", value: 0 },
  { name: "ACTIVE", value: 1 },
  { name: "BANNED", value: 2 },
  { name: "DELETED", value: 3 },
];

export const radioBtnOptions = [
  { name: "NA", value: "NA" },
  { name: "Yes", value: "Yes" },
  { name: "No", value: "No" },
];
export const radioOptions = [
  { name: "Yes", value: true },
  { name: "No", value: false },
];

export const brandRequirementstatusOptions = [
  { name: "Added", value: 0 },
  { name: "Active", value: 1 },
  { name: "Not Active", value: 2 },
  { name: "Closed", value: 3 },
];
export const publicBtnOptions = [
  { name: "NA", value: "NA" },
  { name: "Private", value: "Private" },
  { name: "Public", value: "Public" },
];
export const reqPublicBtnOptions = [
  { name: "Private", value: "Private" },
  { name: "Public", value: "Public" },
];

export const gasBankOptions = [
  { name: "NA", value: "NA" },
  { name: "Central", value: "Central" },
  { name: "Split", value: "Split" },
  { name: "Both", value: "Both" },
];

export const openingOptions = [
  { name: "NA", value: "NA" },
  { name: "Shutter", value: "Shutter" },
  { name: "Gate", value: "Gate" },
  { name: "Both (Gate & Shutter)", value: "Both(Gate & Shutter)" },
];

export const ownershipOptions = [
  { name: "Broker", value: "broker" },
  { name: "Landlord", value: "landlord" },
  { name: "NA", value: "na" },
];
export const verificationStatedOptions = [
  { name: "Not Started", value: 0 },
  { name: "VERIFIED", value: 1 },
];

export const propertyStatusOption = [
  { name: "Created", value: 0 },
  { name: "Closed", value: 1 },
  { name: "Under Review", value: 2 },
  { name: "Not Relevant", value: 3 },
];

export const propertyFloor = [
  { name: "BASEMENT", value: -1 },
  { name: "GROUND", value: 0 },
  { name: "LOWER GROUND FLOOR", value: 101 },
  { name: "UPPER GROUND FLOOR", value: 102 },
  { name: "1ST", value: 1 },
  { name: "2ND", value: 2 },
  { name: "3RD", value: 3 },
  { name: "4TH", value: 4 },
  { name: "5TH", value: 5 },
  { name: "6TH", value: 6 },
  { name: "7TH", value: 7 },
  { name: "8TH", value: 8 },
  { name: "9TH", value: 9 },
  { name: "10TH", value: 10 },
];

export const propertyFor = ["Lease", "Buy"];
export const landType = ["Residential", "Mixed Used", "Commercial"];
export const locatedIn = [
  { name: "NA", value: "NA" },
  { name: "Mall", value: "Mall" },
  { name: "Commercial Project", value: "Commercial Project" },
  { name: "Residential Project", value: "Residential Project" },
  { name: "Retail Shopping Complex", value: "Retail Shopping Complex" },
  { name: "Market/High Street", value: "Market/High Street" },
];
export const leasableFloor = [
  "NA",
  "Basement",
  "Ground",
  "Mezzanine",
  "Upper",
  "Ground",
  "Lower Ground",
  "First",
  "Second",
  "Third",
  "Fourth",
  "Fifth",
];
export const propertyType = ["Single Unit", "Entire Floor", "Full Building"];
export const ownershipType = ["Landlord", "Broker"];
export const facing = ["North", "East", "West", "South"];
export const facingPlacement = [
  "One Side Open",
  "Two Side Open",
  "Three Side Open",
  "Four Side Open",
];
export const gasBankOption = [
  { name: "NA", value: "NA" },
  { name: "Central", value: "Central" },
  { name: "Split", value: "Split" },
  { name: "Both", value: "Both" },
];

export const landTypeOptions = ["Residential", "Mixed Used", "Commercial"];

export const locatedInOptions = [
  { name: "NA", value: "NA" },
  { name: "Mall", value: "Mall" },
  { name: "Commercial Project", value: "Commercial Project" },
  { name: "Residential Project", value: "Residential Project" },
  { name: "Retail Shopping Complex", value: "Retail Shopping Complex" },
  { name: "Market/High Street", value: "Market/High Street" },
];
export const openingTypeOption = [
  { name: "NA", value: "NA" },
  { name: "Shutter", value: "Shutter" },
  { name: "Gate", value: "Gate" },
  { name: "Both", value: "Both(Gate & Shutter)" },
];
export const JobApplicantStatusOptions = [
  { name: "Not Selected", value: null },
  { name: "Not Reviewed", value: 0 },
  { name: "Initial Communication", value: 1 },
  { name: "Not Relevant", value: 2 },
  { name: "Relevant", value: 3 },
  { name: "Interviewing", value: 4 },
  { name: "Onboard", value: 5 },
  { name: "Reject", value: 6 },
];

export const visibilityStatusOptions = [
  { name: "Internal", value: true },
  { name: "Public", value: false },
];

export const modeOptions = [
  { name: "Online", value: "Online" },
  { name: "In Person", value: "In Person" },
  { name: "Hybrid", value: "Hybrid" },
];

export const jobStatusOptions = [
  { name: "Not Active", value: "Not Active" },
  { name: "Active", value: "Active" },
  { name: "Hold", value: "Hold" },
  { name: "Close", value: "Close" },
];

export const selectTypeOptions = [
  { name: "Default", value: 0 },
  { name: "Premium", value: 1 },
];

export const brokerPropertyStatus = {
  0: "Submitted and On Review",    
  1: "Accepted by agprop", 
  2: "Reviewing by Brand",    
  3: "Accepted by Brand for Review",   
  4: "Property Visit Schedule",   
  5: "Accepted",   
  6: "Due Diligence",   
  7: "Onboard",   
  8: "Reject By agprop",   
  9: "Reject By Brand",   
}

export const brokerstatusOptions = [
  { name: "Accept", value: brokerPropertyStatus['3'] },
  { name: "Reject", value: brokerPropertyStatus['9'] },
];

export const APP_TYPE = 'Brand'

export const requirementStatusOptions = [
  { name: "Active", value: "Active" },
  { name: "In Progress", value: "In Progress" },
  { name: "Not Active", value: "Not Active" },
  { name: "Close", value: "Close" },
];