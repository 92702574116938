import { apiPath } from "@/config/apiPath";
import axios from "@/util/axios";

const state = {
  companies: [],
};

const getters = {
  getCompany: (state) => state.companies,
};

const mutations = {
  setCompany: (state, payload) => (state.companies = payload),
  doNothing: (state) => state,
  updateByCompany: (state, updatedcompanies) => {
    state.companies.forEach((company) => {
      if (company.id == updatedcompanies.id) {
        // Update Data
        company.name = updatedcompanies.name;
        company.description = updatedcompanies.description;
        company.logo = updatedcompanies.logo;
        company.links.twitter = updatedcompanies.links.twitter;
        company.links.linkedin = updatedcompanies.links.linkedin;
        company.links.web = updatedcompanies.links.web;
      }
    });
  },
};

const actions = {
  /**
   * @description Get All summeryData
   * @param {Number} data - summeryData Object
   * @returns Promise
   */
  async fetchAllCompanyPage({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(apiPath.currentCompany.get)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setCompany", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Update company Page
   * @param {Object} data - Update company
   * @returns Promise
   */
  updateCompany({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(apiPath.currentCompany.update, data)
        .then(async (res) => {
          if (res.data.success) {
            commit("doNothing");
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * @description Get a Single Fetch Subscriptions
   * @returns Promise
   */
  async fetchSubscriptionsById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(apiPath.subscription.current`${id}`)
        .then((res) => {
          if (res.data.success) {
            commit("doNothing");
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

   /**
   * @description Get All summeryData
   * @param {Number} data - summeryData Object
   * @returns Promise
   */
   async fetchAllSubscription({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(apiPath.subscription.getAll)
        .then(async (res) => {
          if (res.data.success) {
            await commit("doNothing");
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
    /**
  * @description Updload File by User
  * @param {Object} data - Update user files
  * @returns Promise
  */
    uploadFileByCompany({ commit }, data) {
      const formData = new FormData();
      formData.append("file", data.file);
      // formData.append("dirType", data.type);
      formData.append("app_type", data.app_type);
      return new Promise((resolve, reject) => {
        axios
          .post(apiPath.currentCompany.uploadFile, formData)
          .then((res) => {
            if (res.data.success || res.data.status) {
              commit("doNothing");
              resolve(res);
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
