import Vue from "vue";
import Vuex from "vuex";
import authStore from "./module/auth.store";
import brandRequirementsStore from "./module/brandRequirements.store";
import brandsStore from "./module/brands.store";
import officesStore from "./module/offices.store";
import permissionsStore from "./module/permission.store";
import propertiesStore from "./module/properties.store";
import rolesStore from "./module/roles.store";
import usersStore from "./module/users.store";
import summaryStore from "./module/summary.store";
import agpaiStore from "./module/agpai.store";
import smartSearchStore from "./module/smartSearch.store";
import tagsStore from "./module/tags.store";
import landlordStore from "./module/landlord.store";
import brokerStore from "./module/broker.store";
import companyStore from "./module/company.store";
import proposalStore from "./module/proposal.store";
import externalPropertyStore from "./module/externalPropertyStore";
import brokerPropertiesStore from "./module/brokerProperties.store";
import otpStore from "./module/otp.store";


Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    drawer: true,
    leftSidePanelStatus: false,
    edialog: {
      isEdialogVisible: false,
      eMessage: "",
      eTitle: "",
    },
    snackbar: {
      isSnackBarVisible: false,
      snackBarMessage: "",
    },
  },
  getters: {
    getSnackbarMessage: (state) => state.snackbar.snackBarMessage,
    getEdialogMessage: (state) => state.edialog.eMessage,
    getEdialogTitle: (state) => state.edialog.eTitle,
  },
  mutations: {
    setDrawer: (state, payload) => (state.drawer = payload),
    toggleDrawer: (state) => (state.drawer = !state.drawer),
    toggleEdialog: (state) =>
      (state.edialog.isEdialogVisible = !state.edialog.isEdialogVisible),
    setEdialogMessage: (state, payload) => (state.edialog.eMessage = payload),
    setEdialogTitle: (state, payload) => (state.edialog.eTitle = payload),
    setEdialogVisible: (state, payload) =>
      (state.edialog.isEdialogVisible = payload),
    setSnackbarVisible: (state, payload) =>
      (state.snackbar.isSnackBarVisible = payload),
    toggleSnackbar: (state) =>
      (state.snackbar.isSnackBarVisible = !state.snackbar.isSnackBarVisible),
    setSnackbarMessage: (state, payload) =>
      (state.snackbar.snackBarMessage = payload),
    toggleLeftSidePanelStatus: (state, value = '') =>
      (state.leftSidePanelStatus = value !== '' ? value : !state.leftSidePanelStatus),
  },
  actions: {},
  modules: {
    brandsStore,
    usersStore,
    authStore,
    rolesStore,
    officesStore,
    brandRequirementsStore,
    propertiesStore,
    permissionsStore,
    summaryStore,
    agpaiStore,
    smartSearchStore,
    tagsStore,
    landlordStore,
    brokerStore,
    companyStore,
    proposalStore,
    externalPropertyStore,
    brokerPropertiesStore,
    otpStore
  },
});
