import { apiPath } from "@/config/apiPath";
import router from "@/router";
import axiosInstance from "@/util/axios";
import { jwtDecode } from "jwt-decode";
import { app_types } from "@/util/constants/role";
import { APP_TYPE } from '@/util/common';

const state = {
  currentUser: null,
  accessToken: null,
  refreshToken: null,
  userLoggedIn: false,
  currentPermission: null,
  currentBrand: null,
  currentSubscription: null,
};

const getters = {
  getUser: (state) => state.currentUser,
  getAccessToken: (state) => state.accessToken,
  getRefreshToken: (state) => state.refreshToken,
};

const mutations = {
  setUser: (state, payload) => {
    state.currentUser = payload;
    state.currentPermission = payload.permissions;
    state.currentBrand = payload.company;
    localStorage.setItem("userInfo", JSON.stringify(payload));
  },
  setCurrentUserImages: (state, payload) => {
    if(state.currentUser && state.currentUser.images){
      state.currentUser.images = payload;
    }
  },
  setAccessToken: (state, payload) => {
    state.accessToken = payload;
    localStorage.setItem("accessToken", payload);
  },
  setCurrentSubscription: (state, payload) =>
    (state.currentSubscription = payload.subscription),
  changeUserState: (state, payload) => (state.userLoggedIn = payload),
  clearAll: (state) => {
    state.currentUser = null;
    state.accessToken = null;
    state.refreshToken = null;
    state.userLoggedIn = false;
    localStorage.clear();
  },
  doNothing: (state) => state,
};

const actions = {
  login({ commit }, payload) {
    payload = {
      ...payload,
      appType: app_types.Brand,
    }
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(apiPath.auth.login, payload)
        .then((res) => {
          if (res.status == 200) {
            // redirect to the home page
            if ((res.data.user.app_type == APP_TYPE) && res.data.is_user_verified) {
              commit("setUser", res.data.user);
              commit("setAccessToken", res.data.accessToken);
              commit("changeUserState", true);
              commit("setCurrentSubscription", jwtDecode(res.data.accessToken));
              // localStorage.setItem("accessToken", res.data.accessToken);
              // localStorage.setItem("userInfo", JSON.stringify(res.data.user));
              resolve(res.data);
            } else if(res.data.user.app_type == APP_TYPE && !res.data.is_user_verified) {
              resolve(res.data);
            } else {
              commit("clearAll");
              if (window.location.pathname != "/login") {
                return router.push({ name: "login-page" });
              }
              reject({
                success: false,
                message: "Account does not exist",
              });
            }
          }
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  refreshToken({ commit }) {
    return new Promise((resovle, reject) => {
      axiosInstance
        .get(apiPath.auth.refreshToken, {
          withCredentials: true,
        })
        .then((res) => {
          // console.log("token ref", res);
          if (res.status == 200) {
            commit("setAccessToken", res.data.accessToken);
            commit("setCurrentSubscription", jwtDecode(res.data.accessToken));
            localStorage.setItem("accessToken", res.data.accessToken);
            resovle(res);
          }
        })
        .catch((err) => {
          reject(err);
          console.log(err, "err in refresh");
        });
    });
  },

  logout({ dispatch }) {
    console.log("calling logout");
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(apiPath.auth.logout)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          console.log(err);
        })
        .finally(() => {
          dispatch('clearUserSession');
        });
    });
  },

  // description: Get current User Data
  getCurrentUserData({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(apiPath.auth.getCurrentUser)
        .then((res) => {
          if (res.status == 200) {
            commit("doNothing");
            if(res?.data?.data){
              commit("setUser", res.data.data);
              commit("setCurrentUserImages", res.data.data?.images);
            }
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  clearUserSession({ commit }) {
    commit("clearAll");
    document.cookie =
      "refreshToken; expires=Monday, 01 Jan 1970 00:00:00 UTC;";
    if(router.currentRoute?.name !== 'login-page') {
      return router.push({ name: "login-page" });  
    }
  },

  signUp({commit}, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(apiPath.auth.signup, payload)
        .then((res) => {
          commit("doNothing");
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  checkIsDuplicateBrandName(_, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(apiPath.auth.checkIsDuplicateCompanyName, payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          reject(e.response.data);
        });
    });
  },
  verifyOTP(_, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(apiPath.auth.verifyOTP, payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          reject(e.response.data);
        });
    });
  },

  acceptTermsConditions(_, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(apiPath.auth.termsConditions, payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          reject(e.response.data);
        });
    });
  },

  resendOTP(_, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(apiPath.auth.resendOTP, payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          reject(e.response.data);
        });
    });
  },
  reportIssue(_, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(apiPath.auth.reportIssue, payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          reject(e.response.data);
        });
    });
  },
  
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
